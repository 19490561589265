@tailwind base;
@tailwind components;
@tailwind utilities;

/* If the user prefers dark mode, 
we of course apply color-scheme: dark, as usual */
@media (prefers-color-scheme: dark) {
    html {
        color-scheme: light;
    }
}

/* If the user prefers light mode, 
we still enforce color-scheme: dark, despite the user preference */
@media (prefers-color-scheme: light) {
    html {
        color-scheme: light;
    }
}